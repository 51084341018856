export default class BinanceStream {
  constructor() {
    this.tvIntervals = {
      '1': '1m',
      '3': '3m',
      '5': '5m',
      '15': '15m',
      '30': '30m',
      '60': '1h',
      '120': '2h',
      '240': '4h',
      '360': '6h',
      '480': '8h',
      '720': '12h',
      'D': '1d',
      '1D': '1d',
      '3D': '3d',
      'W': '1w',
      '1W': '1w',
      'M': '1M',
      '1M': '1M',
    };    
    
    this.streams = {}; // e.g: {'BTCUSDT': { paramStr: '', data:{}, callback:  } }
    this.subscriptions = new Map();
    this._is_open = false;
    this._createSocket();
  }

  _getBinanceListenKey() {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_BACKEND_URL + '/v1/crypto_room/ws/auth').then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json.listenKey);
      }).catch((error) => {
        console.log(error);
      });
    });
  }
  
  _createSocket() {
    this._getBinanceListenKey().then(listenKey => {
      this._ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/${listenKey}`);

      this._ws.onopen = (e) => {
        this._is_open = true;
      }
  
      this._ws.onclose = () => {
        // console.warn('Binance WS Closed')
        this._is_open = false;
      }
  
      this._ws.onerror = (err) => {
        console.warn('WS Error', err)
        this._is_open = false;
      }
  
      this._ws.onmessage = (msg) => {
        if (!msg?.data) return
        let sData = JSON.parse(msg.data);
        try {
          if (sData && sData.k) {
            let { s } = sData
            let { o, h, l, v, c, T, t, i } = sData.k
            const channelString = String(`${s.toLowerCase()}@kline_${i}`);
            const subscriptionItem = this.subscriptions.get(channelString);
            if (subscriptionItem === undefined) {
              return;
            }
            const lastBar = subscriptionItem.lastBar;

            let bar;
            if (t >= lastBar.closeTime) { 
              bar = {
                time: t,
                close: parseFloat(c),
                open: parseFloat(o),
                high: parseFloat(h),
                low: parseFloat(l),
                volume: parseFloat(v),
                closeTime: T,
                openTime: t,
              }
              // console.log('new bar', E, t, T, new Date(lastBar.time).toLocaleString(), new Date(lastBar.closeTime).toLocaleString(), t >= lastBar.closeTime, new Date(lastBar.time).getTimezoneOffset());
            } else {
              bar = {
                ...lastBar,
                high: Math.max(lastBar.high, parseFloat(h)),
                low: Math.min(lastBar.low, parseFloat(l)),
                close: parseFloat(c),
              };
              // console.log('update bar', E, t, T, new Date(lastBar.time).toLocaleString(), new Date(lastBar.closeTime).toLocaleString(), t >= lastBar.closeTime, new Date(lastBar.time).getTimezoneOffset());
            }

            this.subscriptions.get(channelString).lastBar = bar;
            this.subscriptions.get(channelString).handlers.forEach(handler => handler.callback(bar));
          }
        }
        catch (e) {
          console.error(e)
        }
      }
    });
  }

  subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, lastBar) {
    try {
      let binanceInterval = this.tvIntervals[resolution];
      console.log('CHART RESOLUTION', resolution);
      console.log('CHART INTERVAL', binanceInterval);
      const channelString = `${symbolInfo.name.toLowerCase()}@kline_${binanceInterval}`
      const req_id = Math.floor(Math.random() * 1000000);
      console.log('Chart is subscribing to:', channelString);
      console.log('Subscription Id: ', req_id);
      
      const obj = {
        method: "SUBSCRIBE",
        params: [
          channelString
        ],
        id: req_id
      }
      
      const handler = {
        id: subscribeUID,
        callback: onRealtimeCallback,
        reset_cache_callback: onResetCacheNeededCallback
      }

      if (this.subscriptions.has(channelString)) {
        this.subscriptions.get(channelString).handlers.push(handler) 
      } else {
        if (this._ws.readyState === 1) {
          this._ws.send(JSON.stringify(obj)); 
          this.subscriptions.set(channelString, {
            subscribeUID,
            binanceInterval,
            lastBar,
            handlers: [handler],
          });
        } else {
          this._ws.onopen = (e) => {
            this._ws.send(JSON.stringify(obj));
            this.subscriptions.set(channelString, {
              subscribeUID,
              binanceInterval,
              lastBar,
              handlers: [handler]
            });
          };
        }
      };
    }
    catch (e) {
      console.error(e)
    }
  }

  unsubscribeFromStream(subscriberUID) {
    try {
      for (const channelString of this.subscriptions.keys()) {
        const channelData = this.subscriptions.get(channelString);
        const handlerIndex = channelData.handlers.findIndex(handler => handler.id = subscriberUID);

        if (handlerIndex !== -1) {
          channelData.handlers.splice(handlerIndex, 1);

          if (channelData.handlers.length === 0) {
            const obj = {
              method: "UNSUBSCRIBE",
              params: [
                channelString
              ],
              id: 1
            };
            this.subscriptions.delete(channelString);
            if (this._ws.readyState === 1) {
              this._ws.send(JSON.stringify(obj))
            }
            break;
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

}