
export default class SaveLoadAdapter {

  constructor(props) {
    this.debug = props.debug;
    this.user_id = props.user_id;
    this.client = process.env.REACT_APP_PARENT_URL;
  }

  _fetch_all_charts() {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_BACKEND_URL + `/v1/charts?client=${this.client}&user=${this.user_id}`).then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json.data);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }

  getAllCharts() {
    return new Promise((resolve, reject) => {
      this._fetch_all_charts().then(charts => {
        resolve(charts);
      }).catch(error => {
        reject(error);
      });
    });
  }

  _delete_chart(chartId) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/charts?user=${this.user_id}&client=${this.client}&chart=${chartId}`, {
        method: 'DELETE' 
      }).then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }
    
  removeChart(chartId) {
    return new Promise((resolve, reject) => {
      this._delete_chart(chartId).then(() => {
        resolve();
      }).catch(error => {
        reject();
      })
    });
  }

  _save_chart(chartData) {
    return new Promise((resolve, reject) => {
      chartData.id = Math.random().toString();
      chartData.timestamp = new Date().valueOf();
      fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/charts?user=${this.user_id}&client=${this.client}`, { 
        method: 'POST',
        mode: 'cors',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(chartData)
      })
      .then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }

  _save_chart_as(chartData) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/charts?user=${this.user_id}&client=${this.client}&chart=${chartData.id}`, { 
        method: 'POST',
        mode: 'cors',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(chartData)
      })
      .then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }

  saveChart(chartData) {
    return new Promise((resolve, reject) => {
      if (!chartData.id) {
        this._save_chart(chartData).then(chartId => {
          resolve(chartId);
        }).catch(error => {
          resolve(); //could this be reject?
        });
      } else {
        this._save_chart_as(chartData).then(chartId => {
          resolve(chartId);
        }).catch(error => {
          console.log(chartData);
          resolve(); //could this be reject?
        });
      }  
    });
  }

  _fetch_chart_contents(chartId) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/charts?user=${this.user_id}&client=${this.client}&chart=${chartId}`).then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }

  getChartContent(chartId) {
    return new Promise((resolve, reject) => {
      this._fetch_chart_contents(chartId).then(chartContents => {
        resolve(chartContents.data.content);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  _fetch_all_study_templates() {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/study_templates?user=${this.user_id}&client=${this.client}`).then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json.data);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }

  getAllStudyTemplates() {
    return new Promise((resolve, reject) => {
      this._fetch_all_study_templates().then(studyTemplates => {
        resolve(studyTemplates);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  _remove_study_template(studyTemplateData) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/study_templates?user=${this.user_id}&client=${this.client}&template=${studyTemplateData.name}`, {
        method: 'DELETE' 
      }).then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }

  removeStudyTemplate(studyTemplateData) {
    return new Promise((resolve, reject) => {
      this._remove_study_template(studyTemplateData).then(() => {
        resolve();
      }).catch(error => {
        console.log(error);
        reject(error);
      })
    });
  }

  _save_study_template(chartData) {
    return new Promise((resolve, reject) => {
      chartData.id = Math.random().toString();
      chartData.timestamp = new Date().valueOf();
      fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/study_templates?user=${this.user_id}&client=${this.client}`, { 
        method: 'POST',
        mode: 'cors',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(chartData)
      })
      .then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }

  _save_study_template_as(chartData) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/study_templates?user=${this.user_id}&client=${this.client}&chart=${chartData.id}`, { 
        method: 'POST',
        mode: 'cors',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(chartData)
      })
      .then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }

  saveStudyTemplate(studyTemplateData) {
    return new Promise((resolve, reject) => {
      this._save_study_template(studyTemplateData).then(() => {
        resolve();
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  _fetch_study_template_contents(studyTemplateData) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/study_templates?user=${this.user_id}&client=${this.client}&template=${studyTemplateData.name}`).then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json.data.content);
      }).catch((error) => {
        console.log(error);
        reject(error)
      });
    });
  }

  getStudyTemplateContent(studyTemplateData) {
    return new Promise((resolve, reject) => {
      this._fetch_study_template_contents(studyTemplateData).then(studyTemplateContent => {
        resolve(studyTemplateContent);
      }).catch(error => {
        console.log(error);
        reject(error);
      })
    });
  }
}
