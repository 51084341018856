export default class SocketClient {

  constructor() {
    this.tv_to_td_intervals = {
      '1': '1min',
      '5': '5min',
      '15': '15min',
      '30': '30min',
      '60': '1h',
      '120': '2h',
      '240': '4h',
      'D': '1day',
      '1D': '1day',
      'W': '1week',
      '1W': '1week',
      'M': '1month',
      '1M': '1month',
    };    
    
    this.subscriptions = new Map();
    setInterval(() => {this._pollBackendForChartData();}, 1 * 60 * 1000);
  }

  async _pollBackendForChartData() {
    for (const subscriptionName of this.subscriptions.keys()) {
      const subscription = this.subscriptions.get(subscriptionName);
      const url = process.env.REACT_APP_BACKEND_URL + `/v1/rebel_levels/candlesticks?symbol=${subscription.symbol_info.name}&interval=${subscription.interval}&exchange=${subscription.symbol_info.list_exchange}&outputsize=1&order=ASC`
      await fetch(url)
            .then((response) => response.json())
            .then((response_json) => {
              console.log(JSON.stringify(response_json));
              const kline = response_json[0];
              const kline_from = new Date(kline['datetime']);
              this.subscriptions.get(subscriptionName).handlers.forEach((handler) => {
                handler.callback({
                  time: Math.floor(kline_from.getTime()),
                  low: parseFloat(kline['low']),
                  high: parseFloat(kline['high']),
                  open: parseFloat(kline['open']),
                  close: parseFloat(kline['close']),
                  volume: parseFloat(kline['volume'])
                });
              });
            })
    };
  }

  subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, lastBar) {
    try {
      let twelveDataInterval = this.tv_to_td_intervals[resolution];
      let subscriptionName = `${symbolInfo.name.toLowerCase()}_${twelveDataInterval}`;
      
      const handler = {
        id: subscribeUID,
        callback: onRealtimeCallback,
        reset_cache_callback: onResetCacheNeededCallback
      }

      if (this.subscriptions.has(subscriptionName)) {
        this.subscriptions.get(subscriptionName).handlers.push(handler) 
      } else {
        this.subscriptions.set(subscriptionName, {
          id: subscribeUID,
          symbol_info: symbolInfo,
          interval: twelveDataInterval,
          last_bar: lastBar,
          handlers: [handler],
        });
        this.subscriptions.get(subscriptionName).handlers.forEach(() => {
          console.log();
        });
      };
    }
    catch (e) {
      console.error(e)
    }
  }

  unsubscribeFromStream(subscriberUID) {
    try {
      for (const subscriptionName of this.subscriptions.keys()) {
        const subscriptionData = this.subscriptions.get(subscriptionName);
        const handlerIndex = subscriptionData.handlers.findIndex(handler => handler.id = subscriberUID);

        if (handlerIndex !== -1) {
          subscriptionData.handlers.splice(handlerIndex, 1);

          if (subscriptionData.handlers.length === 0) {
            this.subscriptions.delete(subscriptionName);
          }
        }
      }
    } catch (e) {
      console.error(e)
    }
  }
}