import React from 'react';
import RebelLevelsConfig from './chart_configs/rebel_levels_config';
import CryptoRoomConfig from './chart_configs/crypto_room_config';
import ChartWidget from './ChartWidget';

export default class Chart extends React.PureComponent {

  constructor(props) {
		super(props);
    switch(this.props.service) {
      case 'rebel_levels':
        this.chart_config = new RebelLevelsConfig({user_id: this.props.user_id});
        break;
      case 'crypto_room':
        this.chart_config = new CryptoRoomConfig({user_id: this.props.user_id});
        break;
      default:
        console.error('Subscriber service name not recognized in chart config');
    }
  }

  render() {
		return (
      <ChartWidget
        user_id={this.props.user_id}
        chart_config={this.chart_config}
      />
		);
	}
}