import React from 'react'
import ReactDOM from 'react-dom';
import './index.css'
import { TVChartContainer } from './components/TVChartContainer/index'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import OpsCenter from './OpsCenter';
import Watchlist from './Watchlist';

ReactDOM.render(
  // <StyledEngineProvider injectFirst>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<OpsCenter/>}/>
          <Route path="/chart" element={<TVChartContainer isPopout={true} />}/>
          <Route path="/watchlist" element={<Watchlist isPopout={true}/>}/>
        </Routes>
      </Router>
    </React.StrictMode>
  // </StyledEngineProvider>
  , document.getElementById('root')
);

