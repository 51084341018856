import * as React from 'react';
import { widget } from './charting_library';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export default class ChartWidget extends React.PureComponent {

	tvWidget = null;

	constructor(props) {
		super(props);

    this.chart_config = this.props.chart_config;
    this.chartProps = this.props.chart_config.getPropsForMRService();
		this.ref = React.createRef();

    const context = this;
    this.chart_config.construct(context);

    this.state = {
      symbol: '...',
      exchange: '...',
      last_updated: false
    };

    this.setLevelsDrawingTrigger = this
	}

  componentDidMount() {
    window.addEventListener('message', this.handleMessage, false);
    window.parent.postMessage(JSON.stringify({message: 'iframe-loaded'}), '*');

    const widgetProps = {
      user_id: this.props.user_id,
      debug: false,
      container: this.ref.current,
      library_path: '/charting_library/',
      locale: getLanguageFromURL() || 'en',
      disabled_features: ['header_fullscreen_button', 'header_screenshot', 'popup_hints'],
      enabled_features: ['study_templates'],
      charts_storage_url: process.env.REACT_APP_BACKEND_URL,
      charts_storage_api_version: 'v1',
      fullscreen: false,
      autosize: true,
      theme: 'dark',
      auto_save_delay: 5,
      symbol_search_request_delay: 500
    };

    Object.keys(this.chartProps).forEach((key) => {
      widgetProps[key] = this.chartProps[key];
    });

		const tvWidget = new widget(widgetProps);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {
      const context = this;
      this.chart_config.setUpChart(context, tvWidget);

      tvWidget.headerReady().then(() => {
        this.chart_config.setUpHeader(context, tvWidget);
      });

      const symbol = tvWidget.chart().symbolExt()
      this.setState({
        symbol: symbol.symbol,
        exchange: symbol.exchange
      });
		});
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
  }

	render() {
    const context = this;
		return this.chart_config.getHtml(context);
	}
}