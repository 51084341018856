import './index.css';
import React from 'react';
import './Watchlist.css';
import HamburgerMenu from './HamburgerMenu';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ColumnHeader from './ColumnHeader';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { styled } from "@mui/material/styles";


const StyledAutocomplete = styled(Autocomplete)({
  color: 'red',
  fontFamily: "Trebuchet MS",
  alignSelf: 'center',
  justifyContent: 'center',
  minWidth: '100%', 
  float: 'right',
  "& .MuiInputBase-root": {
    minWidth: '100px', 
    float: 'right',
    display: 'flex',
    fontFamily: "Trebuchet MS",
    alignSelf: 'center',
  },

  "& .MuiFormLabel-root": {
    fontFamily: "Trebuchet MS",
    minWidth: '100%', 
    float: 'right',
  },

  "& .MuiAutocomplete-popper": {
    fontFamily: "Trebuchet MS",
  },

  "& .MuiAutocomplete-inputRoot": {
    minWidth: '100%', 
    float: 'right',
    fontFamily: "Trebuchet MS",
    color: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 151, 151)"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 151, 151)"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 151, 151)"
    }
  }
});

const StyledTextField = styled(TextField)({
  backgroundColor: 'rgb(26, 26, 77)',
  fontFamily: "Trebuchet MS",
  width: '100%',
  "& label": {
    color: "white"
  },
  "& label.Mui-focused": {
    color: "rgb(0, 151, 151)"
  },
  "&:hover label": {
    color: "rgb(0, 151, 151)"
  }
});

const StyledIconButton = styled(IconButton)(
  {
    maxWidth: '30px',
    maxHeight: '20px', 
    minWidth: '30px',
    minHeight: '20px',
    padding: '8px 20px',
    color: 'rgb(0, 151, 151)',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'white'
    },
    '&:active': {
      color: 'gray'
    }
  },
);

const StyledSpan = styled('span')({
  fontFamily: 'Trebuchet MS !important', 
  backgroundColor: '#0F0F2B !important', 
  color: 'white', 
  width: 'auto', 
  borderBottom: '2px solid rgb(26, 26, 77)'
});

class Watchlist extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subscribed_ws_channels: [],
      ordered_columns: Array.from([{"name":"Last","slug":"last","order_number":"0"},{"name":"Change","slug":"change","order_number":"1"},{"name":"Change (%)","slug":"change_percentage","order_number":"2"},{"name":"High","slug":"high","order_number":"3"},{"name":"Volume","slug":"volume","order_number":"5"},{"name":"Bid","slug":"bid","order_number":"6"},{"name":"Ask","slug":"ask","order_number":"7"},{"name":"Spread","slug":"spread","order_number":"8"}]),
    };

    this.addWatchlistSymbol = this.addWatchlistSymbol.bind(this);
    this.moveWatchlistRow = this.moveWatchlistRow.bind(this);
    this.moveWatchlistColumn = this.moveWatchlistColumn.bind(this);
    this.removeSymbol = this.removeSymbol.bind(this);
    this.buildSymbolDicts = this.buildSymbolDicts.bind(this);
    this._subscribeToWatchlistStreams = this._subscribeToWatchlistStreams.bind(this);
  };

  buildSymbolDicts(symbol_list) {
    return new Promise((resolve, reject) => {
      this._fetchSymbolInfoDict()
        .then((symbol_info_dict) => {
          var symbol_search_options = [];
          symbol_list.forEach(dictionary => {
            let symbol_info = symbol_info_dict[dictionary.symbol.toUpperCase()]
            if ( symbol_info !== undefined && symbol_info.name !== undefined) {
              symbol_search_options.push({
                label: dictionary.symbol,
                name: symbol_info_dict[dictionary.symbol.toUpperCase()].name
              });
            } else {
              symbol_search_options.push({
                label: dictionary.symbol,
                name: null
              });
            } 
          });
          
          symbol_search_options.sort(function(first, second) {
              const name1 = first.label.toUpperCase();
              const name2 = second.label.toUpperCase();
              if(name1 < name2) {
                  return -1;
              }
              if(name1 > name2) {
                  return 1; 
              }
              return 0;
          });
          resolve(symbol_search_options);
        });
        });
      
  }

  componentDidMount() {
    this._is_mounted = true;
    fetch(process.env.REACT_APP_BACKEND_URL + '/v1/crypto_room/symbols/list')
    .then(response => response.json())
    .then(response => {
      this.buildSymbolDicts(response).then(symbol_search_options => {
        if(this._is_mounted) {
          if (this.state.ordered_columns !== undefined) {
            this.setState({
              symbol_list: symbol_search_options,
              subscribed_ws_channels: [],
            });
          }
          this.setState({
            symbol_list: symbol_search_options,
          });
        } else {
          console.log('Watchlist componentDidMount attempted to set state when unmounted')
        }
      });
    });

    this._fetchWatchlistColumns().then(ordered_columns => {
        this.setState({
          ordered_columns: ordered_columns,
          lastUpdateTime: Date.now(),
          subscribed_ws_channels: []
        }, () => {
          this._fetchWatchlistSymbols().then((ordered_symbols) => {
            this._initiateWatchlistData(ordered_symbols).then(watchlist_map => {
              this.setState({
                watchlist_map: watchlist_map,
                lastUpdateTime: Date.now(),
              }, () => {
                this._createSocket();
              });
            });
          }); 
        })
    })
    .catch(error => {
      console.log('Error fetching columns in ComponentDidMount.', error);
    })
  }

  componentWillUnmount() {
    this._is_mounted = false;
    this._unsubscribeToWatchlistStreams();
    
  }

  _fetchWatchlistSymbols() {
    return new Promise((resolve, reject) => {
      let ordered_watchlist = [];
      fetch(process.env.REACT_APP_BACKEND_URL + `/v1/crypto_room/watchlist/symbols?user_id=${this.props.userId}`)
      .then(response => response.json())
      .then(response => {
        response.forEach(symbol_obj => {
          ordered_watchlist.push({
            symbol: `${symbol_obj.crypto_symbol}usd`,
            order_number: symbol_obj.order_number
          });
        });
        ordered_watchlist.sort((a, b) => a.order_number - b.order_number);
        resolve(ordered_watchlist); 
      });
    });
  }

  _fetchWatchlistColumns() {
    return new Promise((resolve, reject) => {
      let ordered_columns = [];
      let column_slugs = [];
      fetch(process.env.REACT_APP_BACKEND_URL + `/v1/crypto_room/watchlist/columns?user_id=${this.props.userId}`)
      .then(response => {
        return response.json()
      })
      .then(response_json => {
        response_json.forEach(column => {
          if (!column_slugs.includes(column.column_slug)) {
            ordered_columns.push({
              name: column.full_name,
              slug: column.column_slug,
              order_number: column.order_number,
            });
          }
        });
        ordered_columns.sort((a, b) => a.order_number - b.order_number);
        resolve(ordered_columns);
      })
      .catch(error => {
        console.log('Error fetching watchlist columns.', error);
        reject(error)
      });
    });
  }

  _fetchSymbolInfo(symbol) {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_BACKEND_URL + `/v1/crypto_room/symbol/info?symbol=${symbol}`)
        .then(response => {
          return response.json();
        })
        .then(response_json => {
          resolve(response_json);
        })
        .catch(error => {
          console.log(error);
        })
    });
  }

  _fetchSymbolInfoDict() {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_BACKEND_URL + `/v1/crypto_room/symbols/info`)
        .then(response => {
          return response.json();
        })
        .then(response_json => {
          resolve(response_json);
        })
        .catch(error => {
          console.log(error);
        })
    });
  }

  _initiateWatchlistData(watchlist_symbols) {
    return new Promise(resolve => {
      const watchlistMap = new Map();
      watchlist_symbols.forEach(symbol_dict => {
        if (this.state.watchlist_map && this.state.watchlist_map.has(symbol_dict.symbol.toLowerCase())) {
          let previousEntry = this.state.watchlist_map[symbol_dict.symbol];
          previousEntry.order_number = symbol_dict.order_number
          watchlistMap.set(symbol_dict.symbol.toLowerCase(), previousEntry);
        } else {
          fetch(process.env.REACT_APP_BACKEND_URL + `/api/v3/ticker/24hr?symbol=${symbol_dict.symbol.toUpperCase()}`)
          .then(response => {
            return response.json();
          }).then(response_json => {
            this._fetchSymbolInfo(symbol_dict.symbol.toLowerCase().replace('usd', '')).then(results_json => {
              const single_ticker_channel = `${symbol_dict.symbol.toLowerCase()}@ticker`;
              let spread = parseFloat(response_json.askPrice) - parseFloat(response_json.bidPrice);
              watchlistMap[symbol_dict.symbol.toLowerCase()] = {
                name: results_json.name,
                channel: single_ticker_channel,
                order_number: symbol_dict.order_number,
                description: response_json,
                data: {
                  last: { value: response_json.lastPrice, color: 'white' },
                  change: { value: response_json.priceChange, color: 'white' },
                  changePercent: { value: response_json.priceChangePercent, color: 'white' },
                  low: { value: response_json.lowPrice, color: 'white' },
                  high: { value: response_json.highPrice, color: 'white' },
                  volume: { value: response_json.volume, color: 'white' },
                  bid: { value: response_json.bidPrice, color: 'white' },
                  ask: { value: response_json.askPrice, color: 'white' },
                  spread: { value: spread, color: 'white' }
                },
              };
  
            });
          })
        }
      });
      resolve(watchlistMap);
    });
  };


  _subscribeToWatchlistStreamSubset(subscriptions_obj_list) {
    return new Promise(resolve => {
      subscriptions_obj_list.forEach(obj => {
        if(this._ws.OPEN && this._is_mounted) {
          this._ws.send(JSON.stringify(obj));
        }
      })
      resolve();
    });
  }


  async _subscribeToWatchlistStreams() {
    let subscription_obj_list = [];
    for (const symbol of Object.keys(this.state.watchlist_map)) {
      if (this.state.subscribed_ws_channels.includes(this.state.watchlist_map[symbol].channel)) {
        continue;
      } else {
        subscription_obj_list.push({
          method: "SUBSCRIBE",
          params: [
            this.state.watchlist_map[symbol].channel
          ],
          id: 1
        });

        if(this._is_mounted) {
          this.setState( {
            subscribed_ws_channels: [...this.state.subscribed_ws_channels, this.state.watchlist_map[symbol].channel]
          });
        } else {
          console.log('Watchlist _subscribeToWatchlistStreams attempted to set state when unmounted')
        }
      }

      if(subscription_obj_list.length === 5) {
        await this._subscribeToWatchlistStreamSubset(subscription_obj_list)
        subscription_obj_list = [];
        await this._delay(1000);
      }
    }
    if(subscription_obj_list.length <= 5 && subscription_obj_list.length >= 0) {
      await this._subscribeToWatchlistStreamSubset(subscription_obj_list)
    }
  }

  async _unsubscribeToWatchlistStreams() {
    if(this.state.watchlist_map) {
      Object.keys(this.state.watchlist_map).forEach((symbol) => {
        if (this.state.subscribed_ws_channels.includes(this.state.watchlist_map[symbol].channel)) {
        } else {
          const unsubscription_object = {
            method: "UNSUBSCRIBE",
            params: [
              this.state.watchlist_map[symbol].channel
            ],
            id: 1
          };
    
          this._ws.send(JSON.stringify(unsubscription_object));
    
          if(this._is_mounted) {
            this.setState(prevState => ({
              subscribed_ws_channels: prevState.subscription_obj_list.filter(sub_obj => sub_obj.params[0] !== prevState.watchlist_map[symbol].channel) 
            }));
          } else {
            console.log('Watchlist _unsubscribeToWatchlistStreams attempted to set state when unmounted')
          }
          this._ws.close();
        }
      })
    }    
  }

  _delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  _getBinanceListenKey() {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_BACKEND_URL + '/v1/crypto_room/ws/auth').then((response) => {
        return response.json();
      }).then((response_json) => {
        resolve(response_json.listenKey);
      }).catch((error) => {
        console.log(error);
      });
      }
    );
  }

  _createSocket() {
    this._getBinanceListenKey().then((listenKey) => {
      this._ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/${listenKey}`);

      this._ws.onopen = (e) => {
        this._subscribeToWatchlistStreams();
      }
  
      this._ws.onclose = () => {
        console.warn('Watchlist Websocket Closed')
      }
  
      this._ws.onerror = (err) => {
        console.warn('Watchlist Websocket Error', err)
      }
  
      this._ws.onmessage = (msg) => {
        let tickerData = JSON.parse(msg.data);
        if (tickerData.s !== undefined && this._is_mounted) {
          let spread = parseFloat(tickerData.a) - parseFloat(tickerData.b);
          let watchlist_map_copy = new Map();
          Object.keys(this.state.watchlist_map).forEach((key) => {
            watchlist_map_copy[key] = this.state.watchlist_map[key];
          });
          if (Object.keys(this.state.watchlist_map).includes(tickerData.s.toLowerCase())) {
            watchlist_map_copy[tickerData.s.toLowerCase()] = {
              name: this.state.watchlist_map[tickerData.s.toLowerCase()].name,
              data: {
                last: {value: tickerData.c, color: this.getNumberColor(this.state.watchlist_map[tickerData.s.toLowerCase()].data.last.value, tickerData.c)},
                change: {value: tickerData.p, color: this.getNumberColor(this.state.watchlist_map[tickerData.s.toLowerCase()].data.change.value, tickerData.p)},
                changePercent: {value: tickerData.P, color: this.getNumberColor(this.state.watchlist_map[tickerData.s.toLowerCase()].data.changePercent.value, tickerData.P)},
                low: {value: tickerData.l, color: this.getNumberColor(this.state.watchlist_map[tickerData.s.toLowerCase()].data.low.value, tickerData.l)},
                high: {value: tickerData.h, color: this.getNumberColor(this.state.watchlist_map[tickerData.s.toLowerCase()].data.high.value, tickerData.h)},
                volume: {value: tickerData.v, color: this.getNumberColor(this.state.watchlist_map[tickerData.s.toLowerCase()].data.volume.value, tickerData.v)},
                bid: {value: tickerData.b, color: this.getNumberColor(this.state.watchlist_map[tickerData.s.toLowerCase()].data.bid.value, tickerData.b)},
                ask: {value: tickerData.a, color: this.getNumberColor(this.state.watchlist_map[tickerData.s.toLowerCase()].data.ask.value, tickerData.a)},
                spread: {value: spread, color: this.getNumberColor(this.state.watchlist_map[tickerData.s.toLowerCase()].data.spread.value, spread)}
              },
              order_number: this.state.watchlist_map[tickerData.s.toLowerCase()].order_number,
              channel: `${tickerData.s.toLowerCase()}@ticker`,
            };
            
            if(this._is_mounted) {
              this.setState({
                watchlist_map: watchlist_map_copy,
                lastUpdateTime: Date.now(),
              });
            } else {
              console.log('Watchlist _ws.onmessage attempted to set state when unmounted')
            }
          }
        }
      }
    })
  };

  formatDecimal(input, decimal_places) {
    const decimal = parseFloat(input).toFixed(decimal_places);
    var parts = decimal.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  getNumberColor(oldValue, newValue) {
    if ( oldValue > newValue) {
      return '#FF3131';
    } else if ( newValue > oldValue ) {
      return '#0BDA51';
    } else {
      return 'white';
    }
  }

  moveWatchlistRow(index, symbol, move_type) {
    fetch(process.env.REACT_APP_BACKEND_URL + `/v1/crypto_room/watchlist/row/move?user_id=${this.props.userId}&symbol=${symbol.replace('usd', '')}&move_type=${move_type}&original_index=${index}`, {
      method: 'POST',
    }).then(response => {
      this._fetchWatchlistSymbols().then((ordered_symbols) => {
        this._initiateWatchlistData(ordered_symbols).then(watchlist_map => {
          this.props.setWatchlistGlobalLastUpdate();
          if(this._is_mounted) {
            this.setState({
              watchlist_map: watchlist_map,
              lastUpdateTime: Date.now(),
            }, () => this._createSocket());
          } else {
            console.log('Watchlist moveWatchlistRow attempted to set state when unmounted')
          }
          
        });
      }); 
    }).catch(error => {
      console.log('MOVE WATCHLIST ROW ERROR', error);
    });
  }

  moveWatchlistColumn(index, slug, move_type) {
    fetch(process.env.REACT_APP_BACKEND_URL + `/v1/crypto_room/watchlist/column/move?user_id=${this.props.userId}&slug=${slug}&move_type=${move_type}&original_index=${index}`, {
      method: 'POST',
    }).then(response => {
      this._fetchWatchlistColumns().then((ordered_columns) => {
        this.props.setWatchlistGlobalLastUpdate();
        if(this._is_mounted) {
          this.setState({
            ordered_columns: ordered_columns,
            lastUpdateTime: Date.now(),
          });
        } else {
          console.log('Watchlist moveWatchlistColumn attempted to set state when unmounted')
        }
      });  
    }).catch(error => {
      console.log('MOVE WATCHLIST COLUMN ERROR', error);
    });
  }

  async addWatchlistSymbol(event, isPopout) {
    console.log(`this.addWatchlistSymbol(${event}, ${isPopout}) called`);
    const symbol_selector = ( isPopout ) ? this.props.watchlistWindow.document.getElementById('symbol-autocomplete-popout') : window.document.getElementById( 'symbol-autocomplete-popin');

    console.log(symbol_selector.value);
    if(symbol_selector && symbol_selector.value !== undefined && symbol_selector.value !== '' ) {
      fetch(process.env.REACT_APP_BACKEND_URL + `/v1/crypto_room/watchlist/symbols?user_id=${this.props.userId}&symbol=${symbol_selector.value.toLowerCase()}`, {
        method: 'POST',
      }).then(response => {
        console.log(response);
        this._fetchWatchlistSymbols().then((ordered_symbols) => {
          this._initiateWatchlistData(ordered_symbols).then(watchlist_map => {
            if(this._is_mounted) {
              this.setState({
                watchlist_map: watchlist_map,
                lastUpdateTime: Date.now(),
              }, () => {
                this.props.setWatchlistGlobalLastUpdate();
                this._createSocket()
              });
            } else {
              console.log('Watchlist addWatchlistSymbol attempted to set state when unmounted')
            }
          });
        }); 
      }).catch(error => {
        console.log('ADD SYMBOL ERROR', error);
      })
    };
  }

  removeSymbol(symbol, original_idx) {
    fetch(process.env.REACT_APP_BACKEND_URL + `/v1/crypto_room/watchlist/symbols?user_id=${this.props.userId}&symbol=${symbol.toLowerCase().replace('usd', '')}&original_index=${original_idx}`, {
      method: 'DELETE',
    }).then(response => {
      this._fetchWatchlistSymbols().then((ordered_symbols) => {
        this._initiateWatchlistData(ordered_symbols).then(watchlist_map => {
          this.props.setWatchlistGlobalLastUpdate();
          if(this._is_mounted) {
            this.setState({
              watchlist_map: watchlist_map,
              lastUpdateTime: Date.now(),
            }, () => this._createSocket());
          } else {
            console.log('Watchlist removeSymbol attempted to set state when unmounted')
          }
        });
      }); 
    }).catch(error => {
      console.log('REMOVE SYMBOL ERROR', error);
    });
  }

  render() {
    if (this.state.watchlist_map !== undefined ) {
      const watchlist_data = this.state.watchlist_map;
      
      const sortedSymbolList = Object.keys(this.state.watchlist_map).sort((a, b) => watchlist_data[a].order_number - watchlist_data[b].order_number);
      const symbolColumnMinWidth = ( this.props.isPopout ) ? '90px' : '6vw';
      return (
        <div key={sortedSymbolList + this.state.ordered_columns + this.props.globalWatchlistLastUpdate} id='watchlist-container' style={( this.props.isPopout ) ? { width:  '775px', height: '100vh' } : { width:  '100%', height: '100%' }}>
          <div id='watchlist-title' key={JSON.stringify(this.state.symbol_list)}>
              <div id="title-text-container">
                <h3 id='title-text'>Watchlist</h3>
              </div>
              <div id='add-symbol-button-container'>
                <Tooltip title='Add Symbol To Watchlist'>
                  <StyledIconButton
                    onClick={(event) => {
                      console.log('Add Symbol to Watchlist clicked', event);
                      this.addWatchlistSymbol(event, this.props.isPopout)
                    }}
                  >
                    <AddIcon/>
                  </StyledIconButton>
                </Tooltip>
              </div>
              { this.state.symbol_list ? (
              <div id='symbol-selector-container'>
                <StyledAutocomplete
                  selectOnFocus
                  freeSolo
                  id={( this.props.isPopout ) ? 'symbol-autocomplete-popout' : 'symbol-autocomplete-popin'}
                  getOptionLabel={(option) => {
                    if( option.label !== undefined) {
                      return option.label;
                    } else {
                      return option;
                    }
                  }}
                  disableportal={`${true}`}
                  key={this.state.symbol_list}
                  options={this.state.symbol_list}
                  ListboxProps={{ 
                    style: { maxHeight: '200px', overflow: 'auto', backgroundColor: 'rgb(36, 35, 107)', scrollbarWidth: 'none'},
                  }}
                  // style={{}}
                  justifycontent="center"
                  renderInput={(params) =>
                    <StyledTextField 
                      disableportal={`${true}`}
                      {...params} 
                      size="small"
                      label="Add to Watchlist"
                      InputLabelProps={{
                        shrink: true,
                        style: {maxHeight: '20px'}
                      }}
                    />}
                    renderOption={(props, option) => {
                      const { label, name } = option;
                      return (
                        <StyledSpan className='symbol-option' {...props} >
                          { name === null ? ( label ) : ( label + ' - ' + name ) }
                        </StyledSpan>
                      );
                    }}
                  onChange={this.changeSymbol}
                />
                
              </div>
              ) : (
                <div key={this.state.symbol_list}/>
              )}
              {this.props.isPopout ? (
                <Tooltip title='Close Watchlist Pop Out'>
                  <StyledIconButton
                    id='popout-button'
                    onClick={this.props.closeExternalWatchlist}
                  >
                    <CloseIcon/>
                  </StyledIconButton>
                </Tooltip>
                ) : (
                <Tooltip title='Pop Out Watchlist'>
                  <StyledIconButton
                    id='popout-button'
                    onClick={this.props.openExternalWatchlist}
                  >
                    <OpenInNewIcon/>
                  </StyledIconButton>
                </Tooltip>
              )}
          </div>
          <div id='watchlist-table-container'>
            <table id='watchlist-table' cellSpacing={0}>
              <thead className='stick-to-top overlay'>
                <tr className='watchlist-header-row' key={this.state.ordered_columns}>
                  <th className='stick-to-top stick-to-left overlay symbol-column-title-cell' style={{minWidth: symbolColumnMinWidth}}>
                    <p className='symbol-column-title-text'>Symbol</p>
                  </th>
                  {
                    this.state.ordered_columns.map((column, idx) => {
                      return(
                          <ColumnHeader key={column.slug + '' + idx} column={column} moveColumn={this.moveWatchlistColumn} />
                      );
                    })
                  }
                </tr>
              </thead>
              <tbody className='stick-to-left' key={Object.keys(this.state.watchlist_map).toString()}>
                {
                  Object.keys(this.state.watchlist_map).sort((a, b) => watchlist_data[a].order_number - watchlist_data[b].order_number).map((key, idx) => {
                    return (
                      <tr className='watchlist-row' key={key}>
                        <th className='stick-to-left symbol-cell'>
                            <HamburgerMenu symbol={key} index={idx} moveWatchlistRow={this.moveWatchlistRow} removeSymbol={this.removeSymbol}/>
                          <div className='symbol-container'>
                            <Tooltip title={`${watchlist_data[key].name}`}>
                              <p className='symbol-title'>{key.replace('usd', '').toUpperCase()}</p>
                            </Tooltip>
                          </div>
                        </th>
                        {
                          this.state.ordered_columns.map((column, idx) => {
                            switch(column.slug) {
                              case 'last':
                                return(<td key={idx} style={{ color: watchlist_data[key].data.last.color}}>{this.formatDecimal(watchlist_data[key].data.last.value, 2)}</td>);
                              case 'change':
                                return(<td key={idx} style={{ color: watchlist_data[key].data.change.color }}>{this.formatDecimal(watchlist_data[key].data.change.value, 2)}</td>);
                              case 'change_percentage':
                                return(<td key={idx} style={{ color: watchlist_data[key].data.changePercent.color }}>{this.formatDecimal(watchlist_data[key].data.changePercent.value, 2)}</td>);
                              case 'low':
                                return(<td key={idx} style={{ color: watchlist_data[key].data.low.color }}>{this.formatDecimal(watchlist_data[key].data.low.value, 2)}</td>);
                              case 'high':
                                return(<td key={idx} style={{ color: watchlist_data[key].data.high.color }}>{this.formatDecimal(watchlist_data[key].data.high.value, 2)}</td>);
                              case 'volume':
                                return(<td key={idx} style={{ color: watchlist_data[key].data.volume.color }}>{this.formatDecimal(watchlist_data[key].data.volume.value, 2)}</td>);
                              case 'bid':
                                return(<td key={idx} style={{ color: watchlist_data[key].data.bid.color }}>{this.formatDecimal(watchlist_data[key].data.bid.value, 2)}</td>);
                              case 'ask':
                                return(<td key={idx} style={{ color: watchlist_data[key].data.ask.color }}>{this.formatDecimal(watchlist_data[key].data.ask.value, 2)}</td>);
                              case 'spread':
                                return(<td key={idx} style={{ color: watchlist_data[key].data.spread.color }}>{this.formatDecimal(watchlist_data[key].data.spread.value, 2)}</td>);
                              default:
                                return(<td key={idx}> ... </td>);
                            }
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div id='watchlist-container' key={this.state.lastUpdateTime}/>
      );
    }
  }


}

export default Watchlist;
