import CryptoFeed from '../datafeeds/crypto_feed';
import SaveLoadAdapter from '../datafeeds/save_load_adapter';

export default class CryptoRoomConfig {

  constructor(props) {
    this.chartProps = {
      symbol: "Binance:BTCUSD",
      interval: 'D',
      clientId: process.env.REACT_APP_PARENT_URL,
      userId: props.user_id,
      datafeed: new CryptoFeed({ 
        debug: false 
      }),
      save_load_adapter: new SaveLoadAdapter({ 
        debug: false, 
        user_id: props.user_id, 
        client_id: process.env.REACT_APP_PARENT_URL,
      }),
      studies_overrides: {},
      overrides: {
        'paneProperties.backgroundGradientStartColor': '#0F0F2B',
        'paneProperties.backgroundGradientEndColor': 'black',
      },
      custom_css_url: '/chart_styles.css',
      width: '80%',
      height: '60%',
    };
  }

  getPropsForMRService() {
    return this.chartProps;
  }

  setUpChart(context, widget) {

    widget.subscribe('onAutoSaveNeeded', () => {
      widget.saveChartToServer(() => {
        console.log('chart autosaved')
      }, () => {
        console.log('failed to autosave chart')
      });
    });

    widget.getSavedCharts((charts) => {
      if(charts.length > 0) {
        let chart = charts.sort((a, b) => (a.modified_iso < b.modified_iso ? 1 : -1 ))[0];
        widget.loadChartFromServer(chart);
        console.log('last chart loaded');
      }
    });

    widget.chart().onSymbolChanged().subscribe(null, function (symbolData) {
      const symbols = symbolData.description.split(' / ');
      context.setState({
        symbol_str: symbolData.full_name,
        base_symbol: symbols[0],
        quote_symbol: symbols[1]
      });

      widget.saveChartToServer(() => {
        console.log('chart saved');
      }, () => {
        console.log('failed to save chart')
      });

    });

    const symbolExt = widget.chart().symbolExt();
    const symbols = symbolExt.description.split(' / ');
    context.setState({
      symbol_str: symbolExt.full_name,
      base_symbol: symbols[0],
      quote_symbol: symbols[1]
    });

  }

  setUpHeader(context, widget) {
    const button = widget.createButton();
    button.setAttribute('title', 'Click to pop out chart');
    button.classList.add('apply-common-tooltip');

    button.addEventListener('click', () => {
      if (context.props.isPopout) {
        window.close();
      } else {
        window.open(`${process.env.PUBLIC_URL}/chart?symbol=${context.state.symbol}&user=${context.props.user_id}`, '', 'width=600,height=400,left=200,top=200');
      }
    });
    button.innerHTML = 'Pop Out';
  }

  construct(context) {
    context.query_symbol = context.getQueryVariable('symbol');
    context.query_user_id = context.getQueryVariable('user');
    if (context.query_symbol === undefined) {
      context.query_symbol = context.props.symbol;
    }

    if (context.query_user_id === undefined) {
      context.query_user_id = context.props.userId;
    }
  }

  getHtml(context) {
    return (
      <>
        {context.props.isPopout ? (
          <div id='chart-pop-out-container'>
            <div
              ref={ context.ref }
              className={ 'TVChartContainer-popout' }
            />
          </div>
        ) : (
          <div id='chart-pop-in-container'>
            <div
                ref={ context.ref }
                className={ 'TVChartContainer' }
            />
          </div>
        )}
        <div id='tradingview-link-container' >
          <a id='tradingview-link' target='_blank' rel="noreferrer" href={`https://www.tradingview.com/symbols/${context.state.base_symbol}${context.state.quote_symbol}/`}>{context.state.base_symbol} Chart By TradingView</a>
        </div>
      </>
		);
  }
}