import LevelsFeed from '../datafeeds/levels_feed';
import SaveLoadAdapter from '../datafeeds/save_load_adapter';

export default class RebelLevelsConfig {

  constructor(props) {
    this.chartProps = {
      symbol: "AAL",
      interval: 'D',
      clientId: process.env.REACT_APP_PARENT_URL + '.levels',
      userId: props.user_id,
      datafeed: new LevelsFeed({ 
        debug: false 
      }),
      save_load_adapter: new SaveLoadAdapter({ 
        debug: false, 
        user_id: props.user_id, 
        client_id: process.env.REACT_APP_PARENT_URL + '.levels'
      }),
      studies_overrides: {},
      width: '100%',
      height: '100%',
    };
  }

  getPropsForMRService() {
    return this.chartProps;
  }

  setUpChart(context, widget) {
    widget.subscribe('onAutoSaveNeeded', () => {
      widget.saveChartToServer(() => {
        console.log('chart autosaved')
      }, () => {
        console.log('failed to autosave chart')
      });
    });

    widget.getSavedCharts((charts) => {
      if(charts.length > 0) {
        console.log(JSON.stringify(charts));
        let chart = charts.sort((a, b) => (a.modified_iso < b.modified_iso ? 1 : -1 ))[0];
        widget.loadChartFromServer(chart);
        console.log('last chart loaded');
      } else {
        console.log('no last chart found');
      }
    });

    widget.chart().onSymbolChanged().subscribe(null, function (symbolData) {
      context.setLevelsDrawingTrigger(widget, context);
      context.setState({
        symbol: symbolData.ticker,
        exchange: symbolData.exchange
      });

      widget.saveChartToServer(() => {
        console.log('chart saved');
      }, () => {
        console.log('failed to save chart')
      });

    });

    setTimeout(() => {
      console.log('Chart drawing triggered...');
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const symbol = widget.symbolInterval().symbol.split(':')[1];
      const last_bar = widget.chart().getSeries().data().last();
      const bars = widget.chart().getSeries().data().bars();
  
      widget.chart().removeAllShapes();
  
      const url = `${process.env.REACT_APP_BACKEND_URL}/v1/rebel_levels/levels?symbol=${symbol}`;
  
      fetch(url)
      .then((res) => {
        return res.json();
      }).then((levels) => {
        if (context) {
          context.setState({
            last_updated: levels.last_update
          })
        } else {
          this.setState({
            last_updated: levels.last_update
          }); 
        }
        levels.prices.forEach((level) => {
          const green = '#26a69a';
          const red = '#ef5350';
          const white = '#FFFFFF'
          let line_color = white;
          let left_limit = last_bar.value[0];    
          if (level < last_bar.value[3] - 100) { // if level is lower than last bar low
            line_color = red;
            for (let bar_idx = last_bar.index - 1; bar_idx >= 0; bar_idx--) {
              let bar = bars._items[bar_idx]
              let bar_low = bar.value[3];
              if( bar_low <= level ) {
                left_limit = bar.value[0];
                break;
              }
            } 
          } else if ( level > last_bar.value[2] + 100) { // if level higher than last bar high
            line_color = green;
            for (let bar_idx = last_bar.index - 1; bar_idx >= 0; bar_idx--) {
              let bar = bars._items[bar_idx]
              let bar_high = bar.value[2]
              if( bar_high >= level ) {
                left_limit = bar.value[0];
                break;
              }
            } 
          }
    
          widget
            .chart()
            .createMultipointShape(
              [{ time: left_limit + (1 * 24 * 3600), price: level }],
                { shape: 'horizontal_line', text: formatter.format(level), lock: true, disableSave: true, disableUndo: true, zOrder: "top", overrides: { linecolor: line_color, linestyle: '2', showPrice: false, showLabel: true, textcolor: '#FFFFFF', horzLabelsAlign: 'left', vertLabelsAlign: 'bottom' } },
            );
        })
      });
    }, 2000);
  }

  getHtml(context) {
    return (
      <>
        <div id='chart-pop-in-container'>
            <div
                ref={ this.ref }
                className={ 'TVChartContainer' }
            />
        </div>
        <div id='tradingview-link-container' >
          <a key={context.state.symbol} className='alignLeft' id='tradingview-link' target='_blank' rel="noreferrer" href={`https://www.tradingview.com/symbols/${context.state.exchange}-${context.state.symbol}/`}>{context.state.exchange}-{context.state.symbol} Chart By TradingView</a>
          <p key={context.state.symbol+ context.state.last_updated} className='alignRight' id='last-update' >Levels Last Updated: {context.state.last_updated && new Date(context.state.last_updated).toLocaleString()}</p>
          <div style={{clear:'both'}}></div>
        </div>
      </>
		);
  }

}