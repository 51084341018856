import React from 'react';
import './ColumnHeader.css';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from "@mui/material/styles";

const Left = styled(KeyboardArrowLeftIcon)(
  {
    color: '#4344ca',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'white'
    },
    '&:active': {
      color: 'gray'
    }
  }
);

const Right = styled(KeyboardArrowRightIcon)(
  {
    color: '#4344ca',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'white'
    },
    '&:active': {
      color: 'gray'
    }
  }
);


const StyledMenuItem = styled(MenuItem)(
  {
    display: 'inline',
    // height: '20px',
    // width: '100px',
    '& .MuiPopover-root': {
      // height: '20px',
      // width: '100px',
    }
  }
);

const StyledMenu = styled(Menu)(
  {
    color: 'black',
    backgroundColor: 'transparent',
    '& .MuiMenu-paper': {
      backgroundColor: '#0F0F2B',
      border: '1px solid white'
    }
  }
);


const StyledColumnHeader = styled(Paper)(
  {
    color: 'rgb(0, 151, 151)',
    height: '25%',
    textAlign: 'center',
    padding: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      color: 'gray',
    },
    '&:active': {
      color: 'gray'
    }
  },
);

class ColumnHeader extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
    }

    this.open = Boolean(this.state.anchorEl);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick(event) {
    console.log('handle Click', event);
    this.setState({
        anchorEl: event.currentTarget,
    }, () => this.setState({
      open: true
    }));
  }

  handleClose(event, button_style) {
    if(['left', 'right'].includes(button_style)) {
      this.props.moveColumn(this.props.column.order_number, this.props.column.slug, button_style);
    };
    this.setState({
      open: false,
    }, () => this.setState({
      anchorEl: null,
    }));
  }

  render() {
    return(
      <th key={this.props.column.slug} className='stick-to-top metric-title'>
        <StyledColumnHeader
          elevation={0}
          aria-controls={this.state.open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={this.state.open ? 'true' : undefined}
          onClick={this.handleClick}
        >
          <p className='column-text'>{this.props.column.name}</p>
        </StyledColumnHeader>
        <StyledMenu
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleClose}
          marginThreshold={0}
        >
          <StyledMenuItem 
            disablePortal 
            onClick={(event) => this.handleClose(event, 'left')}
          >
            <Left/>
          </StyledMenuItem>
          <StyledMenuItem 
            disablePortal 
            onClick={(event) => this.handleClose(event, 'right')}
          >
            <Right/>
          </StyledMenuItem>
        </StyledMenu>
      </th>
    );
  }
};  

export default ColumnHeader;