import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import IconButton from '@mui/material/IconButton';
import { styled } from "@mui/material/styles";

const Top = styled(KeyboardDoubleArrowUpIcon)(
  {
    color: '#4344ca',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'white'
    },
    '&:active': {
      color: 'gray'
    }
  }
);

const Up = styled(KeyboardArrowUpIcon)(
  {
    color: '#4344ca',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'white'
    },
    '&:active': {
      color: 'gray'
    }
  }
);

const Delete = styled(DeleteForeverIcon)(
  {
    color: 'red',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'white'
    },
    '&:active': {
      color: 'gray'
    }
  }
);

const Down = styled(KeyboardArrowDownIcon)(
  {
    color: '#4344ca',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'white'
    },
    '&:active': {
      color: 'gray'
    }
  }
);

const Bottom = styled(KeyboardDoubleArrowDownIcon)(
  {
    color: '#4344ca',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'white'
    },
    '&:active': {
      color: 'gray'
    }
  }
);


const StyledMenuItem = styled(MenuItem)({});

const StyledMenu = styled(Menu)(
  {
    color: 'black',
    backgroundColor: 'transparent',
    '& .MuiMenu-paper': {
      backgroundColor: '#0F0F2B',
      border: '1px solid white'
    }
  }
);

const StyledIconButton = styled(IconButton)(
  {
    float: 'left',
    margin: '17px 0px 13px 0px',
    maxWidth: '30px',
    maxHeight: '20px', 
    minWidth: '30px',
    minHeight: '20px',
    paddingLeft: '20px',
    color: '#4344ca',
    backgroundColor: 'transparent',
    '&:hover': {
      color: 'white'
    },
    '&:active': {
      color: 'gray'
    }
  },
);

class HamburgerMenu extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      anchorEl: null,
    }

    this.open = Boolean(this.state.anchorEl);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick(event) {
    console.log('handle Click', event);
    this.setState({
        anchorEl: event.currentTarget,
    }, () => this.setState({
      open: true
    }));
  }

  handleClose(event, button_style) {
    if(['top', 'up', 'down', 'bottom'].includes(button_style)) {
      this.props.moveWatchlistRow(this.props.index, this.props.symbol, button_style);
    } else {
      if(['delete',].includes(button_style)) {
        this.props.removeSymbol(this.props.symbol, this.props.index);
      }
    }
    this.setState({
      open: false,
    }, () => this.setState({
      anchorEl: null,
    }));
  }

  render() {
    return(
      <>
        <StyledIconButton
          aria-controls={this.state.open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={this.state.open ? 'true' : undefined}
          onClick={this.handleClick}
        >
          <MenuIcon />
        </StyledIconButton>
        <StyledMenu
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleClose}
        >
          <StyledMenuItem onClick={(event) => this.handleClose(event, 'top')}><Top/></StyledMenuItem>
          <StyledMenuItem onClick={(event) => this.handleClose(event, 'up')}><Up/></StyledMenuItem>
          <StyledMenuItem onClick={(event) => this.handleClose(event, 'delete')}><Delete/></StyledMenuItem>
          <StyledMenuItem onClick={(event) => this.handleClose(event, 'down')}><Down/></StyledMenuItem>
          <StyledMenuItem onClick={(event) => this.handleClose(event, 'bottom')}><Bottom/></StyledMenuItem>
        </StyledMenu>
      </>
    );
  }
};

export default HamburgerMenu;