import binanceWS from './binance_chart_streaming'

export default class binanceAPI {
  constructor(options) {
    this.binanceHost = 'https://api.binance.com';
    this.debug = options.debug || false;
    this.ws = new binanceWS();
    this.lastBarsCache = new Map();
  }

  binanceSymbols() {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/api/v1/exchangeInfo').then(res => {
      return res.json();
    }).then(json => {
      return json.symbols;
    })
  }

  binanceKlines(symbol, interval, startTime, endTime, limit) {
    console.log('binance klines params: ', {symbol, interval, startTime: new Date(startTime).toLocaleString(), startTimeRaw: startTime, endTime: new Date(endTime).toLocaleString(), endTimeRaw: endTime, limit} )
    const url = process.env.REACT_APP_BACKEND_URL + `/api/v3/klines?symbol=${symbol}&interval=${interval}${startTime ? `&startTime=${startTime}` : ''}${endTime ? `&endTime=${endTime}` : ''}${limit ? `&limit=${limit}` : ''}`

    return fetch(url).then(res => {
      return res.json();
    }).then(json => {
      return json;
    })
  }

  onReady(callback) {
    this.binanceSymbols().then((symbols) => {
      this.symbols = symbols;
      callback({
        exchanges: [
          {
            value: 'Binance',
            name: 'Binance',
            desc: 'Binance'
          }
        ],
        symbols_types: [
          {
            name: 'crypto',
            value: 'crypto',
          },
        ],
        supports_marks: true,
        supports_timescale_marks: true,
        supports_time: true,
        supported_resolutions: [
          '1', '3', '5', '15', '30', '60', '120', '240', '360', '480', '720', 'D', '3D', '1W', '1M'
        ]
      });
    }).catch(err => {
      console.error(err);
    })
  }

  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    userInput = userInput.toUpperCase()
    onResultReadyCallback(
      this.symbols.filter((symbol) => {
        return symbol.symbol.indexOf(userInput) >= 0
      }).map((symbol) => {
        return {
          symbol: symbol.symbol,
          full_name: symbol.symbol,
          description: symbol.baseAsset + ' / ' + symbol.quoteAsset,
          ticker: symbol.symbol,
          exchange: 'Binance',
          type: 'crypto'
        }
      })
    )
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    this.debug && console.log('resolveSymbol:', symbolName)

    const comps = symbolName.split(':')
    symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase()

    for (let symbol of this.symbols) {
      if (symbol.symbol === symbolName) {
        setTimeout(() => {
          onSymbolResolvedCallback({
            name: symbol.symbol,
            description: symbol.baseAsset + ' / ' + symbol.quoteAsset,
            ticker: symbol.symbol,
            exchange: 'Binance',
            list_exchange: 'Binance',
            type: 'crypto',
            session: '24x7',
            minmov: 1,
            pricescale: 100,
            timezone: 'Etc/UTC',
            has_intraday: true,
            has_weekly_and_monthly: true,
            currency_code: symbol.quoteAsset,
            supported_resolutions: ['1', '3', '5', '15', '30', '60', '120', '240', '360', '480', '720', 'D', '3D', '1W', '1M'],
            volume_precision: 2,
            visible_plots_set: 'ohlcv', 
            data_status: 'streaming',
          })
        }, 0);
        return;
      }
    }
    onResolveErrorCallback('not found')
  }

  getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    let { from, to, firstDataRequest } = periodParams;
    const interval = this.ws.tvIntervals[resolution]
    from = new Date((from * 1000))
    to = new Date((to * 1000))
    from = Math.floor(from.getTime() / 1000);
    to = Math.floor(to.getTime() / 1000);
    if (!interval) {
      onErrorCallback('Invalid interval')
    }

    let totalKlines = [];
    const kLinesLimit = 500;


    const finishKlines = () => {
      if (totalKlines.length === 0) {
        setTimeout(() => {onHistoryCallback([], { noData: true });});
      } else {
        let bars = [];
        totalKlines.forEach(kline => {
          if (kline[0] >= from && kline[0] < to) {
            bars = [...bars, {
              time: parseInt(kline[0]),
              low: parseFloat(kline[3]),
              high: parseFloat(kline[2]),
              open: parseFloat(kline[1]),
              close: parseFloat(kline[4]),
              volume: parseFloat(kline[5]),
              closeTime: parseInt(kline[6]),
              openTime: parseInt(kline[0]),
            }];
          }
        });
        if (firstDataRequest) {
          this.lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
          console.log('FIRST DATA REQUEST', { ...bars[bars.length - 1] })
        } else {

        }
        try {
          setTimeout(() => {onHistoryCallback(bars, { noData: false });});
        } catch (e) {
          onErrorCallback(`Error in 'finishKlines' func`)
        }
      }
    }

    const getKlines = async (from, to) => {
      try {
        const data = await this.binanceKlines(symbolInfo.name, interval, from, to, kLinesLimit);
        totalKlines = totalKlines.concat(data);
        if (data.length === kLinesLimit) {
          from = data[data.length - 1][0] + 1;
          getKlines(from, to);
        } else {
          finishKlines();
        }
      }
      catch (e) {
        console.error(e)
        onErrorCallback(`Error in 'getKlines' func`)
      }
    }

    from *= 1000;
    to *= 1000;
    getKlines(from, to);
    return;
  }

  subscribeBars(symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) {
    console.log('subscribeBars symbolInfo', symbolInfo);
    this.ws.subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, this.lastBarsCache.get(symbolInfo.full_name))
  }

  unsubscribeBars(subscriberUID) {
    this.ws.unsubscribeFromStream(subscriberUID)
  }
}
