import twelve_data_stream from './twelve_data_stream';

export default class LevelsFeed {
  constructor(options) {
    this.twelveDataUrl = process.env.REACT_APP_BACKEND_URL;
    this.levelsUrl = process.env.REACT_APP_BACKEND_URL;
    this.debug = options.debug || false;
    this.stream = new twelve_data_stream();
    this.lastBarsCache = new Map();
    this.exchange_timezones = [];
    this.symbols = [];
    this.symbol_levels = {};
  }

  twelveDataSymbols() {
    return new Promise((resolve, reject) => {
      fetch(this.levelsUrl + '/v1/rebel_levels/symbols')
        .then((res) => {
          return res.json();
        }).then((td_symbols) => {
          this.exchange_timezones = td_symbols.exchange_timezones;
          this.symbol_types = td_symbols.symbol_types;
          this.symbol_levels = td_symbols.symbol_levels;
          this.symbols = td_symbols.listed_symbols.sort((a, b) => {
            a.last_update = new Date(this.symbol_levels[a.symbol].last_update)
            b.last_update = new Date(this.symbol_levels[b.symbol].last_update)
            return new Date(this.symbol_levels[b.symbol].last_update) - new Date(this.symbol_levels[a.symbol].last_update)
          });
          resolve();
        });
    });
  };

  async twelveDataKlinesFromTo(symbol, interval, startTime, endTime, limit, exchange, timezone) {
    if(startTime === undefined) {
      let output = await this._twelveDataKlinesTo(symbol, interval, endTime, limit, exchange, timezone);
      return output;
    }

    let startDate = null;
    let endDate = null;

    if(startTime <= endTime) {
      startDate = new Date(startTime.getTime() - (startTime.getTime() % 86400000));
      startDate = startDate.toLocaleString('en-US', {timeZone: timezone, hour12: false}).replace(', ', ' ');
      endDate = new Date(endTime.getTime() - (endTime.getTime() % 86400000) + 86400000);
      endDate = endDate.toLocaleString('en-US', {timeZone: timezone, hour12: false}).replace(', ', ' ');
    } else {
      startDate = new Date(endTime.getTime() - (endTime.getTime() % 86400000));
      startDate = startDate.toLocaleString('en-US', {timeZone: timezone, hour12: false}).replace(', ', ' ');
      endDate = new Date(startTime.getTime() - (startTime.getTime() % 86400000) + 86400000);
      endDate = endDate.toLocaleString('en-US', {timeZone: timezone, hour12: false}).replace(', ', ' ');
    }

    const url = process.env.REACT_APP_BACKEND_URL + `/v1/rebel_levels/candlesticks?symbol=${symbol}&interval=${interval}&exchange=${exchange}&start_date=${startDate}&end_date=${endDate}&outputsize=${limit}&order=ASC`
    
    return fetch(url).then(res => {
      return res.json();
    }).then(json => {
      return json;
    })
  }

  async _twelveDataKlinesTo(symbol, interval, endTime, limit, exchange, timezone) {
    // let endDate = new Date(endTime.getTime() - (endTime.getTime() % 86400000) + 86400000);
    // endDate = endDate.toLocaleString('en-US', {timeZone: timezone, hour12: false}).replace(', ', ' ');

    console.log(`_twelveDataKlinesTo(${symbol}, ${interval}, ${endTime}, ${limit}, ${exchange}, ${timezone})`);

    let url = process.env.REACT_APP_BACKEND_URL + `/v1/rebel_levels/candlesticks?symbol=${symbol}&interval=${interval}&exchange=${exchange}&end_date=${endTime}&outputsize=${limit}&order=ASC`
    if (endTime === undefined) { 
      url = process.env.REACT_APP_BACKEND_URL + `/v1/rebel_levels/candlesticks?symbol=${symbol}&interval=${interval}&exchange=${exchange}&outputsize=${limit}&order=ASC`
    }

    return fetch(url).then(res => {
      return res.json();
    }).then(json => {
      return json;
    })
  }

  async onReady(callback) {
    await this.twelveDataSymbols();

    const opts = {
      exchanges: [],
      symbols_types: [],
      supports_marks: true,
      supports_timescale_marks: true,
      supports_time: true,
      supported_resolutions: ['D']
    };

    for (const [exchange, timezone] of Object.entries(this.exchange_timezones)) {
      opts.exchanges.push({
        value: exchange,
        name: exchange,
        desc: exchange
      });
    };

    this.symbol_types.forEach((symbol_type) => {
      opts.symbols_types.push({
        name: symbol_type,
        value: symbol_type
      });
    });

    callback(opts);
  }

  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    userInput = userInput.toUpperCase()
    exchange = exchange.toUpperCase()

    onResultReadyCallback(
      this.symbols.filter((symbol) => {
        return (symbol.symbol.indexOf(userInput) >= 0 || symbol.full_name.toUpperCase().indexOf(userInput.toUpperCase()) >= 0) && symbol.type == symbolType;
      }).map((symbol) => {
        return {
          symbol: symbol.symbol,
          description: `${symbol.full_name}   -   ${symbol.last_update.toLocaleString('en-us', { year:"numeric", month:"short", day:"numeric"})}`,
          ticker: symbol.symbol,
          exchange: symbol.exchange,
          type: symbol.type
        }
      })
    );
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    this.debug && console.log('resolveSymbol:', symbolName)

    const comps = symbolName.split(':')
    symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase()

    for (let symbol of this.symbols) {
      if (symbol.symbol === symbolName) {
        setTimeout(() => {
          console.log(symbol.exchange + ':' + symbol.symbol + symbol.currency);
          onSymbolResolvedCallback({
            name: symbol.symbol,
            description: symbol.full_name + ' / ' + symbol.currency,
            ticker: symbol.symbol,
            exchange: symbol.exchange,
            list_exchange: symbol.exchange,
            type: symbol.type,
            session: '0930-1600',
            minmov: 1,
            pricescale: 100,
            timezone: symbol.timezone,
            has_intraday: true,
            has_weekly_and_monthly: true,
            currency_code: symbol.currency,
            // supported_resolutions: ['1', '5', '15', '30', '60', '120', '240', 'D', '1W', '1M'],
            supported_resolutions: ['D'],
            volume_precision: 2,
            visible_plots_set: 'ohlcv', 
            data_status: 'streaming'
          })
        });
        return;
      }
    }
    onResolveErrorCallback('not found');
  }

  getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
    let { from, to, countBack, firstDataRequest } = periodParams; // The most important function of getBars() is to provide a specific number of bars corresponding with the value of countBack
    const interval = this.stream.tv_to_td_intervals[resolution];
    // from = new Date((from * 1000));
    from = from * 1000;
    // to = new Date((to * 1000));
    to = to * 1000;

    let totalKlines = [];
    let kLinesLimit = 500;

    console.log(`getBars() called \n\tfrom: ${from.toLocaleString()}\n\tto: ${to.toLocaleString()}\n\tneeded bar count: ${countBack}\n\tinterval: ${interval}\n\tsymbol: ${symbolInfo.name}`)
    const finishKlines = () => {
      if (totalKlines.length === 0) {
        setTimeout(() => {onHistoryCallback([], { noData: true });});
      } else {
        let bars = [];
        totalKlines.forEach(kline => {
          if (kline === undefined) {
            setTimeout(() => {onHistoryCallback([], { noData: true });});
            return;
          } else {
            if (kline.hasOwnProperty('datetime')) {
              let kline_from = new Date(kline['datetime'])
              bars.push({
                time: Math.floor(kline_from.getTime()),
                low: parseFloat(kline['low']),
                high: parseFloat(kline['high']),
                open: parseFloat(kline['open']),
                close: parseFloat(kline['close']),
                volume: parseFloat(kline['volume'])
              });
            }
          }

        });
        if(firstDataRequest) {

        }
        try {
          console.log(`Total Bars sent ${bars.length}`);
          setTimeout(() => {onHistoryCallback(bars, { noData: false });});
        } catch (e) {
          onErrorCallback(`Error in 'finishKlines' func`)
        }
      }
    };

    const getKlines = async (from, to, limit) => {
      console.log(`getKlines(${from}, ${to}, ${limit})`)

      try {
        if (limit === undefined) {
          limit = kLinesLimit
        }  else if (limit == 0) {
          finishKlines();
        }
        const data = await this.twelveDataKlinesFromTo(symbolInfo.name, interval, from, to, limit, symbolInfo.exchange, symbolInfo.timezone);

        if (data && totalKlines.length > 0 && new Date(data[0]['datetime']).getTime() < new Date(totalKlines[0]['datetime']).getTime()) {
          totalKlines = [...data, ...totalKlines]
        } else if (data && totalKlines) {
          totalKlines = totalKlines.concat(data);
        }
        

        if (data && (data.length === limit && totalKlines.length < countBack)) {
          to = new Date(data[0]['datetime']);
          getKlines(undefined, to, countBack - totalKlines.length);
        } else {
          finishKlines();
        }
      }
      catch (e) {
        console.error(e)
        onErrorCallback(`Error in 'getKlines' func`)
        return;
      }
    };

    getKlines(undefined, to, countBack);
    return;
  }

  subscribeBars(symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) {
    console.log('subscribeBars symbolInfo', symbolInfo);
    this.stream.subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, this.lastBarsCache.get(symbolInfo.full_name))
  }

  unsubscribeBars(subscriberUID) {
    console.log('unsubscribeBars subscriberUID', subscriberUID  );
    this.stream.unsubscribeFromStream(subscriberUID)
  }
}
