import { useState } from "react";
import "./Login.css";

const Login = (props) => {
  const [UnameOrEmail, setUnameOrEmail] = useState("");
  const [password, setpassword] = useState("");

  const handlesubmit = (e) => {
    e.preventDefault();
    let postBody = {
      username: UnameOrEmail,
      password: password,
    };
    window.parent.postMessage(JSON.stringify(postBody), '*');
    console.log('sent login message', postBody.username);
  };

  return (
    <div id='login-container'>
      <h1 id='login-title'>Login</h1>
      <p id='login-description'>Please log in to view the crypto room.</p>
      <form id='login-form' onSubmit={handlesubmit}>
        <div className="form-group">
          <input
            type="text"
            placeholder="email or username"
            required
            value={UnameOrEmail}
            onChange={(e) => setUnameOrEmail(e.target.value)}
            className="Input"
          />
        </div>
        <div className="form-group">
          <input
            type="Password"
            placeholder="password"
            required
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            className="Input"
          />
        </div>
        <button id='submit-button' type="submit">Log in</button>
      </form>
    </div>
  );
};
export default Login;