import React from 'react';
import ReactDOM from 'react-dom';

class Popout extends React.PureComponent {

  constructor(props) {
    super(props);
    this.containerEl = document.createElement('div');
    this.containerEl.classList.add('container-element');
    // this.containerEl = document.getElementById('overlays');
    this.externalWindow = null;
    this.document = document;
    this.state = {
      styles_loaded: false
    };
  }

  copyStyles(sourceDoc, targetDoc) {
    return new Promise((resolve) => {
      Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
        if (styleSheet.cssRules) {
          const newStyleEl = sourceDoc.createElement('style');
    
          Array.from(styleSheet.cssRules).forEach(cssRule => {
            const { cssText, type } = cssRule;
            let returnText = cssText;
            if ([3, 5].includes(type)) {
              returnText = cssText
                .split('url(')
                .map((line) => {
                  if (line[1] === '/') {
                    return `${line.slice(0, 1)}${
                      window.location.origin
                    }${line.slice(1)}`;
                  }
                  return line;
                })
                .join('url(');
            }

            newStyleEl.appendChild(sourceDoc.createTextNode(returnText));
          });
    
          targetDoc.head.appendChild(newStyleEl);
        } else if (styleSheet.href) {
          console.log('StyleSheet HREF', styleSheet.href);
          const newLinkEl = sourceDoc.createElement('link');
    
          newLinkEl.rel = 'stylesheet';
          newLinkEl.href = styleSheet.href;
          targetDoc.head.appendChild(newLinkEl);
        };
      });
      resolve();
    });
  }

  componentDidMount() {
    this.externalWindow = window.open('', '', 'innerWidth=620,height=370,left=200,top=200');
    this.props.setWatchlistWindow(this.externalWindow);

    this.copyStyles(this.document, this.externalWindow.document)
    this.setState({
      styles_loaded: true
    });
    this.externalWindow.document.body.appendChild(this.containerEl);
  }

  componentWillUnmount() {
    if (this.externalWindow) {
      this.externalWindow.close();
    }
  }

  render() {
    return ReactDOM.createPortal(<div key={this.state.styles_loaded}>{this.props.children}</div>, this.containerEl);
  }
}

export default Popout;